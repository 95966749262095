<template>
  <div class="industry">
    <div class="industry-top">
      <div class="industry-topone industry-topfirst">
        <div class="industry-topfirst-title">
          <div class="industry-topfirst-title-l">
            {{ queryName }}农村供水总人口
          </div>
          <div class="industry-topfirst-title-r">
            {{ industry.cntyTotcounPop }}万
          </div>
        </div>
        <div class="industry-topfirst-con">
          <div
            class="industry-topfirst-con-top"
            v-if="localGetItem('userLevel') == 1"
          >
            <!-- <div class="industry-progress"> -->
            <a-progress
              :percent="industry.rat"
              :show-info="false"
              :strokeWidth="24"
            />
            <div
              class=" industry-topfirst-con-bottom flex align-end justify-center"
            >
              <div class="industry-topfirst-con-bottom-left">
                占{{ queryName }}总人口
              </div>
              <div class="industry-topfirst-con-bottom-right">
                {{ industry.rat }}%
              </div>
            </div>
            <!-- </div> -->
            <!-- <div style="margin-left:5px">{{ industry.numCity }} 个市</div> -->
            <div style="margin-top:35px">
              {{ industry.numCity }}个市{{ industry.numCountry }}个县
            </div>
          </div>
          <div
            class="industry-topfirst-con-top"
            v-if="localGetItem('userLevel') == 2"
          >
            <a-progress
              :percent="industry.rat"
              :show-info="false"
              :strokeWidth="24"
            />
            <div
              class=" industry-topfirst-con-bottom flex align-end justify-center"
            >
              <div class="industry-topfirst-con-bottom-left">
                占{{ queryName }}总人口
              </div>
              <div class="industry-topfirst-con-bottom-right">
                {{ industry.rat }}%
              </div>
            </div>
            <!-- <div style="margin-left:5px">{{ industry.numCountry }} 个县</div> -->
            <div style="margin-top:35px">
              {{ industry.numCountry }}个县{{ industry.numTown }}个乡镇
            </div>
          </div>
          <div
            class="industry-topfirst-con-top"
            v-if="localGetItem('userLevel') == 3"
          >
            <a-progress
              :percent="industry.rat"
              :show-info="false"
              :strokeWidth="24"
            />
            <div
              class=" industry-topfirst-con-bottom flex align-end justify-center"
            >
              <div class="industry-topfirst-con-bottom-left">
                占{{ queryName }}总人口
              </div>
              <div class="industry-topfirst-con-bottom-right">
                {{ industry.rat }}%
              </div>
            </div>
            <!-- <div style="margin-left:5px">{{ industry.numTown }} 个乡镇</div> -->
            <div style="margin-top:35px">
              {{ industry.numTown }}个乡镇{{ industry.numVillage }}个村
            </div>
          </div>
        </div>
      </div>
      <div class="industry-topone">
        <div class="industry-topfirst-title">
          <div class="industry-topfirst-title-l">
            {{ queryName }}农村供水工程总数
          </div>
          <div class="industry-topfirst-title-r">{{ industry.plaWasu }}处</div>
        </div>
        <div class="industry-topfirst-chart1">
          <div class="percent1">
            <div>分散工程</div>
            <div>{{ industry.plaDwseRat }}%</div>
          </div>
          <!-- 图1 -->
          <div
            id="ciicle1"
            style="width:86%;height:120%;margin:36px auto 0px"
          ></div>
          <div class="percent2">
            <div>集中工程</div>
            <div>{{ industry.plaCwseRat }}%</div>
          </div>
        </div>
        <div class="industry-topfirst-text">
          <div>
            分散供水工程{{ industry.plaDwse }}处,{{ industry.peoDwse }}万人
          </div>
          <div>
            集中供水工程{{ industry.plaCwse }}处,{{ industry.peoCwse }}万人
          </div>
        </div>
      </div>
      <div class="industry-topone">
        <div class="industry-topfirst-title">
          <div class="industry-topfirst-title-l">规模化供水总数</div>
          <div class="industry-topfirst-title-r">
            {{ modularThreeLeft }}处 {{ modularThreeRight }}万人
          </div>
        </div>
        <div class="industry-topfirst-chart2">
          <div class="chart2-left">
            <div id="bar1" style="width:100%;height:190%;"></div>
          </div>
          <div class="chart2-right">
            <div id="bar2" style="width:100%;height:190%;"></div>
          </div>
        </div>
        <div class="industry-topfirst-chart2-text">
          占{{ queryName }}农村供水总人口{{ 100 }}%
        </div>
        <div class="industry-topfirst-chart2-text">
          占{{ queryName }}农村供水工程总处数{{ 100 }}%
        </div>
      </div>
      <div class="industry-topone">
        <div class="industry-topfirst-title">
          <div class="industry-topfirst-title-l">{{ waterName }}</div>
        </div>
        <div class="industry-progress">
          <a-progress
            :percent="industry.waterRat"
            :show-info="false"
            :strokeWidth="24"
          />
          <div style="font-size:25px;margin-top:10px">
            {{ industry.waterRat }}%
          </div>
        </div>
        <div class="industry-progress-tab">
          <div
            class="industry-progress-tabone"
            :class="waterIndex == index ? 'progress-tabone-select' : ''"
            v-for="(item, index) in tabList"
            :key="index"
            @click="checkWater(index)"
          >
            {{ item }}
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="industry-second">
      <div class="industry-second-up">
        <a-form
          class="ant-advanced-search-form"
          :form="form"
          @submit="handleSearch"
        >
          <a-row :gutter="24">
            <a-col :span="7">
              <!-- <a-form-item label="行政区划：">
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择"
                  @change="handleChangeArea"
                  v-model="param.selectedAreaId"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="(item, index) in xzqh"
                    :key="index"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item> -->
              <a-form-item label="行政区划：">
                <a-cascader
                  :options="options"
                  @change="areaChange"
                  placeholder="请选择"
                  expandTrigger="hover"
                />
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="年度：">
                <a-select
                  style="width: 100%"
                  placeholder="请选择"
                  @change="handleChange2"
                  :value="param.reportYear"
                >
                  <a-select-option v-for="(i, index) in year" :key="index">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!-- <a-col :span="6">
              <a-form-item label="水源类型：">
                <a-select
                  mode="multiple"
                  :default-value="['a1']"
                  style="width: 100%"
                  placeholder="请选择"
                  @change="handleChange"
                >
                  <a-select-option v-for="(i, index) in 25" :key="index">
                    {{ (i + 9).toString(36) + i }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col> -->
            <a-col :span="10" :style="{ textAlign: 'right' }">
              <a-button type="primary" html-type="submit">
                查询
              </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清除
              </a-button>
            </a-col>
          </a-row>
          <a-row v-if="isYX">
            <a-col :span="24">
              <div class="industry-second-up-select">
                <div class="industry-second-up-select-l">
                  工程运行管理单位：
                </div>
                <div class="industry-second-up-select-r">
                  <div
                    v-for="(item, index) in tabListd"
                    :key="index"
                    :class="
                      projectIndex.indexOf(item) !== -1 ? 'select-rd' : ''
                    "
                    @click="checkProject(item)"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="industry-second-ud">
        <div
          class="industry-second-udone"
          :class="contrastIndex == index ? 'progress-tabone-select' : ''"
          v-for="(item, index) in tabLista"
          :key="index"
          @click="checkContrast(index)"
        >
          {{ item }}
          <div></div>
        </div>
      </div>
    </div>
    <!-- 总体分析 -->
    <div id="ztfx">
      <!--  -->
      <div class="industry-third">
        <div class="industry-thirdone">
          <div class="industry-thirdone-title">
            <div class="industry-thirdone-title-l">
              <div class="line"></div>
              <div class="title">农村供水工程分类占比</div>
            </div>
          </div>
          <div class="industry-thirdone-title-r">
            <div
              class="industry-thirdone-title-rdone"
              :class="
                waterProjectIndex1 == index
                  ? 'industry-thirdone-title-rselect'
                  : ''
              "
              v-for="(item, index) in tabListb"
              :key="index"
              @click="waterProject1(index)"
            >
              {{ item }}
            </div>
          </div>
          <div style="width:80%;height:100%;margin-top:10px">
            <div id="ciicle2" style="width:130%;height:100%;"></div>
          </div>
        </div>
        <div class="industry-thirdone">
          <div class="industry-thirdone-title">
            <div class="industry-thirdone-title-l">
              <div class="line"></div>
              <div class="title">农村供水工程分类数量</div>
            </div>
          </div>
          <div class="industry-thirdone-title-r">
            <div
              class="industry-thirdone-title-rdone"
              :class="
                waterProjectIndex2 == index
                  ? 'industry-thirdone-title-rselect'
                  : ''
              "
              v-for="(item, index) in tabListc"
              :key="index"
              @click="waterProject2(index)"
            >
              {{ item }}
            </div>
          </div>
          <div style="position: relative;width:100%;height:90%">
            <div class="linesort">
              <img
                v-if="sortup"
                src="@/assets/img/industry/sortud.png"
                @click="sort()"
                alt=""
              />
              <img
                v-if="!sortup"
                src="@/assets/img/industry/sortup.png"
                @click="sort()"
                alt=""
              />
            </div>
            <div id="line1" style="width:100%;height:100%;"></div>
          </div>
        </div>
      </div>

      <!--  -->
      <div class="industry-four">
        <div class="industry-four-title">
          <div class="industry-four-title-l">数据更新至2020年6月</div>
          <div class="industry-four-title-r" @click="toDetail()">
            查看详情 >
          </div>
        </div>
        <div class="industry-four-table">
          <a-table
            :columns="columns"
            :data-source="data"
            bordered
            :pagination="false"
            size="middle"
            :scroll="{ x: 'calc(700px + 50%)', y: '' }"
          />
        </div>
      </div>
    </div>

    <!-- 区域对比 -->
    <div id="qydb">
      <!--  -->
      <div class="industry-five">
        <div class="industry-thirdone-title">
          <div class="industry-thirdone-title-l">
            <div class="line"></div>
            <div class="title">农村供水工程分类</div>
          </div>
        </div>
        <div class="industry-thirdone-title-r">
          <div
            class="industry-thirdone-title-rdone"
            :class="
              tabListeIndex == index ? 'industry-thirdone-title-rselect' : ''
            "
            v-for="(item, index) in tabListe"
            :key="index"
            @click="tabListeCheck(index)"
          >
            {{ item }}
          </div>
        </div>
        <!-- 图 -->
        <div style="position: relative;width:100%;height:90%" id="tuline2">
          <div id="line2" style="width:100%;height:100%;"></div>
        </div>
        <!-- 表 -->
        <div id="tableline2" style="display:none;margin-top:20px">
          <a-table
            :columns="columns1"
            :data-source="data1"
            :pagination="false"
            bordered
            size="middle"
            :scroll="{ x: 'calc(478px + 50%)', y: 440 }"
          />
        </div>
      </div>
      <!--  -->
      <div class="industry-six">
        <div class="industry-six-l">
          <div class="industry-thirdone-title">
            <div class="industry-thirdone-title-l">
              <div class="line"></div>
              <div class="title">工程类型：</div>
            </div>
          </div>
          <div class="industry-six-l-con">
            <a-radio-group v-model="value" @change="onChange">
              <div class="radio1">
                <a-radio value="1">
                  集中供水工程
                </a-radio>
                <a-icon
                  class="down"
                  id="down"
                  type="down-circle"
                  @click="downClick()"
                />
                <a-icon
                  class="up"
                  id="up"
                  type="up-circle"
                  @click="upClick()"
                />
              </div>
              <div class="checkbox" id="checkboxis">
                <div
                  class="checkbox-s"
                  v-for="(item, index) of es"
                  :key="index"
                >
                  <a-checkbox
                    @change="onChange1"
                    v-model="item.target.checked"
                    :value="item.target.value"
                    :disabled="one == 2"
                  >
                    {{ item.name }}
                  </a-checkbox>
                </div>
              </div>
              <a-radio class="radio2" value="2">
                分散供水工程
              </a-radio>
            </a-radio-group>
          </div>
        </div>
        <div class="industry-six-r">
          <div class="industry-thirdone-title">
            <div class="industry-thirdone-title-l">
              <div class="line"></div>
              <div class="title">农村供水工程分类</div>
            </div>
          </div>
          <div class="industry-thirdone-title-r">
            <div style="margin-right:50px;display: flex;">
              <div
                class="industry-thirdone-title-rdone"
                :class="
                  tabListeIndex1 == index
                    ? 'industry-thirdone-title-rselect'
                    : ''
                "
                v-for="(item, index) in tabListe"
                :key="index"
                @click="tabListeCheck1(index)"
              >
                {{ item }}
              </div>
            </div>
            <div
              class="industry-thirdone-title-rdone"
              :class="
                tabListeIndex2 == index ? 'industry-thirdone-title-rselect' : ''
              "
              v-for="(item, index) in tabListc"
              :key="index"
              @click="waterProject3(index)"
            >
              {{ item }}
            </div>
          </div>
          <div style="position: relative;width:100%;height:90%">
            <div id="tuline3" style="position: relative;width:100%;height:90%">
              <div class="linesort">
                <img
                  v-if="sortup1"
                  src="@/assets/img/industry/sortud.png"
                  @click="sort1()"
                  alt=""
                />
                <img
                  v-if="!sortup1"
                  src="@/assets/img/industry/sortup.png"
                  @click="sort1()"
                  alt=""
                />
              </div>
              <div id="line3" style="width:100%;height:118%;"></div>
            </div>
            <div id="tableline3" style="margin-top:20px">
              <a-table
                :columns="columns2"
                :data-source="data2"
                :pagination="false"
                bordered
                size="middle"
                :scroll="{ x: 'calc(700px+ 50%)', y: 380 }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引人组件
import { getAreas, getDefaultArea } from "@/api/area/index";
import {
  getConsumIndustry,
  getConsumProject,
  getAreaIndustries,
  getIndustrieList
} from "@/api/industry/index";
import { getAreaTreeList } from "@/api/basic/common";
// import { delete } from 'vue/types/umd';

// 表格1数据
const columns = [
  {
    title: "序号",
    dataIndex: "num",
    key: "num",
    width: 50,
    align: "center"
    // fixed: 'left',
  },
  {
    title: "省（自治区、直辖市）市、县级名称",
    dataIndex: "name",
    key: "name",
    width: 150,
    align: "center"
    // fixed: 'left',
  },
  {
    title: "农村供水总人口(万人)",
    dataIndex: "allpopulation",
    key: "allpopulation",
    width: 120,
    sorter: (a, b) => a.allpopulation - b.allpopulation,
    align: "center"
  },
  {
    title: "供水工程总数(处)",
    dataIndex: "allnum",
    key: "allnum",
    width: 120,
    sorter: (a, b) => a.allnum - b.allnum,
    align: "center"
  },
  {
    title: "集中供水工程",
    children: [
      {
        title: "供水人数(万人)",
        dataIndex: "population1",
        key: "population1",
        width: 100,
        sorter: (a, b) => a.population1 - b.population1,
        align: "center"
      },
      {
        title: "工程处数(处)",
        dataIndex: "num1",
        key: "num1",
        width: 100,
        sorter: (a, b) => a.num1 - b.num1,
        align: "center"
      }
    ]
  },
  {
    title: "城市管网延伸工程",
    children: [
      {
        title: "供水人数(万人)",
        dataIndex: "population2",
        key: "population2",
        width: 100,
        sorter: (a, b) => a.population2 - b.population2,
        align: "center"
      },
      {
        title: "工程处数(处)",
        dataIndex: "num2",
        key: "num2",
        width: 100,
        sorter: (a, b) => a.num2 - b.num2,
        align: "center"
      }
    ]
  },
  {
    title: "万人工程",
    children: [
      {
        title: "供水人数(万人)",
        dataIndex: "population3",
        key: "population3",
        width: 100,
        sorter: (a, b) => a.population3 - b.population3,
        align: "center"
      },
      {
        title: "工程处数(处)",
        dataIndex: "num3",
        key: "num3",
        width: 100,
        sorter: (a, b) => a.num3 - b.num3,
        align: "center"
      }
    ]
  },
  {
    title: "千人工程",
    children: [
      {
        title: "供水人数(万人)",
        dataIndex: "population4",
        key: "population4",
        width: 100,
        sorter: (a, b) => a.population4 - b.population4,
        align: "center"
      },
      {
        title: "工程处数(处)",
        dataIndex: "num4",
        key: "num4",
        width: 100,
        sorter: (a, b) => a.num4 - b.num4,
        align: "center"
      }
    ]
  },
  {
    title: "千人以下工程",
    children: [
      {
        title: "供水人数(万人)",
        dataIndex: "population5",
        key: "population5",
        width: 100,
        sorter: (a, b) => a.population5 - b.population5,
        align: "center"
      },
      {
        title: "工程处数(处)",
        dataIndex: "num5",
        key: "num5",
        width: 100,
        sorter: (a, b) => a.num5 - b.num5,
        align: "center"
      }
    ]
  },
  {
    title: "分散供水工程",
    children: [
      {
        title: "供水人数(万人)",
        dataIndex: "population6",
        key: "population6",
        width: 100,
        sorter: (a, b) => a.population6 - b.population6,
        align: "center"
      },
      {
        title: "工程处数(处)",
        dataIndex: "num6",
        key: "num6",
        width: 100,
        sorter: (a, b) => a.num6 - b.num6,
        align: "center"
      }
    ]
  }
];
// 表格2数据
const columns1 = [
  {
    title: "序号",
    dataIndex: "num",
    key: "num",
    width: 50,
    align: "center"
    // fixed: 'left',
  },
  {
    title: "省（自治区、直辖市）市、县级名称",
    dataIndex: "name",
    key: "name",
    width: 100,
    align: "center"
    // fixed: 'left',
  },
  {
    title: "总数（处）",
    dataIndex: "allnum",
    key: "allnum",
    width: 100,
    align: "center"
    // fixed: 'left',
  },
  {
    title: "集中供水工程",
    children: [
      {
        title: "供水人数(万人)",
        dataIndex: "num1",
        key: "num1",
        width: 90,
        align: "center"
      },
      {
        title: "工程处数(处)",
        dataIndex: "population1",
        key: "population1",
        width: 90,
        align: "center"
      },
      {
        title: "人口占比(%)",
        dataIndex: "percent1",
        key: "percent1",
        width: 90,
        align: "center"
      },
      {
        title: "工程占比(%)",
        dataIndex: "percent2",
        key: "percent2",
        width: 90,
        align: "center"
      }
    ]
  },
  {
    title: "分散供水工程",
    children: [
      {
        title: "供水人数(万人)",
        dataIndex: "num2",
        key: "num2",
        width: 90,
        align: "center"
      },
      {
        title: "工程处数(处)",
        dataIndex: "population2",
        key: "population2",
        width: 90,
        align: "center"
      },
      {
        title: "人口占比(%)",
        dataIndex: "percent3",
        key: "percent3",
        width: 90,
        align: "center"
      },
      {
        title: "工程占比(%)",
        dataIndex: "percent4",
        key: "percent4",
        width: 90,
        align: "center"
      }
    ]
  }
];
// 表格3数据
const columns2 = [
  {
    title: "序号",
    dataIndex: "num",
    key: "num",
    width: 50,
    align: "center"
    // fixed: 'left',
  },
  {
    title: "省（自治区、直辖市）市、县级名称",
    dataIndex: "name",
    key: "name",
    width: 100,
    align: "center"
    // fixed: 'left',
  },
  {
    title: "集中供水工程",
    children: [
      {
        title: "供水人数(万人)",
        dataIndex: "num1",
        key: "num1",
        width: 90,
        align: "center"
      },
      {
        title: "工程处数(处)",
        dataIndex: "population1",
        key: "population1",
        width: 90,
        align: "center"
      },
      {
        title: "人口占比(%)",
        dataIndex: "percent1",
        key: "percent1",
        width: 90,
        align: "center"
      },
      {
        title: "工程占比(%)",
        dataIndex: "percent2",
        key: "percent2",
        width: 90,
        align: "center"
      }
    ]
  },
  {
    title: "城市管网延伸工程",
    children: [
      {
        title: "供水人数(万人)",
        dataIndex: "num2",
        key: "num2",
        width: 90,
        align: "center"
      },
      {
        title: "工程处数(处)",
        dataIndex: "population2",
        key: "population2",
        width: 90,
        align: "center"
      },
      {
        title: "人口占比(%)",
        dataIndex: "percent3",
        key: "percent3",
        width: 90,
        align: "center"
      },
      {
        title: "工程占比(%)",
        dataIndex: "percent4",
        key: "percent4",
        width: 90,
        align: "center"
      }
    ]
  },
  {
    title: "万人供水工程",
    children: [
      {
        title: "供水人数(万人)",
        dataIndex: "num3",
        key: "num3",
        width: 90,
        align: "center"
      },
      {
        title: "工程处数(处)",
        dataIndex: "population3",
        key: "population3",
        width: 90,
        align: "center"
      },
      {
        title: "人口占比(%)",
        dataIndex: "percent5",
        key: "percent5",
        width: 90,
        align: "center"
      },
      {
        title: "工程占比(%)",
        dataIndex: "percent6",
        key: "percent6",
        width: 90,
        align: "center"
      }
    ]
  },
  {
    title: "千人供水工程",
    children: [
      {
        title: "供水人数(万人)",
        dataIndex: "num4",
        key: "num4",
        width: 90,
        align: "center"
      },
      {
        title: "工程处数(处)",
        dataIndex: "population4",
        key: "population4",
        width: 90,
        align: "center"
      },
      {
        title: "人口占比(%)",
        dataIndex: "percent7",
        key: "percent7",
        width: 90,
        align: "center"
      },
      {
        title: "工程占比(%)",
        dataIndex: "percent8",
        key: "percent8",
        width: 90,
        align: "center"
      }
    ]
  },
  {
    title: "千人以下集中供水工程",
    children: [
      {
        title: "供水人数(万人)",
        dataIndex: "num5",
        key: "num5",
        width: 90,
        align: "center"
      },
      {
        title: "工程处数(处)",
        dataIndex: "population5",
        key: "population5",
        width: 90,
        align: "center"
      },
      {
        title: "人口占比(%)",
        dataIndex: "percent9",
        key: "percent9",
        width: 90,
        align: "center"
      },
      {
        title: "工程占比(%)",
        dataIndex: "percent10",
        key: "percent10",
        width: 90,
        align: "center"
      }
    ]
  }
];

// 表格1
const data = [];
// for (let i = 0; i < 14; i++) {
//   data.push({
//     key: i,
//     name: "John Brown",
//     num1: 1,
//     population1: 2,
//     num2: 1,
//     population2: 2,
//     num3: 1,
//     population3: 2,
//     num4: 1,
//     population4: 2,
//     num5: 1,
//     population5: 2,
//     num6: 1,
//     population6: 2
//   });
// }
// 表格2
const data1 = [];
// for (let i = 0; i < 14; i++) {
//   data1.push({
//     key: i,
//     name: "山西",
//     num1: 1,
//     population1: 2,
//     percent1: 1,
//     percent2: 2,
//     num2: 1,
//     population2: 2,
//     percent3: 1,
//     percent4: 2,
//   });
// }

// 表格3
const data2 = [];
// for (let i = 0; i < 14; i++) {
//   data2.push({
//     key: i,
//     name: "山西",
//     num1: 1,
//     population1: 2,
//     percent1: 1,
//     percent2: 2,
//     num2: 1,
//     population2: 2,
//     percent3: 1,
//     percent4: 2,
//     num3: 1,
//     population3: 2,
//     percent5: 1,
//     percent6: 2,
//     num4: 1,
//     population4: 2,
//     percent7: 1,
//     percent8: 2,
//     num5: 1,
//     population5: 2,
//     percent9: 1,
//     percent10: 2,
//     num6: 1,
//     population6: 2,
//     percent11: 1,
//     percent12: 2,
//   });
// }
export default {
  data() {
    return {
      one: 1,
      form: this.$form.createForm(this, { name: "advanced_search" }),
      modularThreeLeft: 0, //头部模块三左边
      modularThreeRight: 0, //头部模块三右边

      xzqh: ["江苏省", "北京市", "山西省"], //测试数据/行政区划
      year: [], //测试数据/年度
      options: [
        {
          value: "1",
          label: "山西省"
        }
      ], //行政区划
      // 筛选条件
      param: {
        defaultAreaId: undefined,
        selectedAreaId: undefined,
        areaId: undefined,
        reportYear: "",
        areaName: ""
      },
      oriAreaId: undefined,
      queryName: "全省",
      // 统计数据
      industry: {
        cntyTotcounPop: 0,
        rat: 0,
        numCity: 0,
        numCountry: 0,
        numTown: 0,
        numVillage: 0,
        plaWasu: 0,
        plaWasuRat: 0,
        popWasu: 0,
        plaCwse: 0,
        peoCwse: 0,
        plaCwseRat: 0,
        plaDwse: 0,
        peoDwse: 0,
        plaDwseRat: 0,
        waterList: [],
        waterRat: 0,
        consumProjects: [],
        tableData: []
      },
      tabList: [
        "集中供水率",
        "自来水普及率",
        "规模化供水率",
        "供水保证率",
        "水质达标率"
      ], //集中供水率tab
      waterName: "集中供水率",
      tabListd: [
        "全部",
        "县级水务局",
        "县级供水管理总站",
        "片区或流域等县级派生水管站（所）",
        "乡镇政府或水管站",
        "国有公司",
        "自来水公司",
        "股份制公司",
        "民营公司",
        "外资公司",
        "私人租赁或承包",
        "供水协会",
        "合作组织或用水户协会",
        "村委会",
        "其他"
      ], //工程运行管理单位
      tabLista: ["总体分析", "区域对比"], //总体分析/区域对比tab
      tabListb: ["人口占比", "工程占比"], //全省农村供水工程分类tab
      tabListc: ["供水人数", "工程处数", "人口占比", "工程占比"], //全省农村供水工程分类tab
      tabListe: ["图", "表格"], //农村供水人口分布分类tab

      waterIndex: 0, //切换集中供水率index
      projectIndex: [], //切换工程运行管理单位index
      contrastIndex: 0, //切换总体分析/区域对比index
      waterProjectIndex1: 0, //切换全省农村供水工程分类/人口占比/工程占比index
      waterProjectIndex2: 0, //切换全省农村供水工程分类/人口占比/工程占比/占比index
      tabListeIndex: 0, //切换农村供水人口分布index
      tabListeIndex1: 0, //集中供水工程覆盖人口图表index
      tabListeIndex2: 0, //集中供水工程覆盖人口分类index

      sortup: false, //全省农村供水工程分类/人口占比/工程占比/占比 排序
      sortup1: false, //农村供水人口分布/人口占比/工程占比/占比 排序

      value: "1", //工程类型 单选
      isYX: false, //工程运行管理单位是否展示

      es: [
        {
          name: "城市网管延伸",
          target: { checked: true, value: "3" }
        },
        {
          name: "万人工程",
          target: { checked: true, value: "4" }
        },
        {
          name: "千人工程",
          target: { checked: true, value: "5" }
        },
        {
          name: "千人以下工程",
          target: { checked: true, value: "6" }
        }
      ],
      esValue: ["3", "4", "5", "6"],
      checked: true,

      data,
      columns,
      data1,
      columns1,
      data2,
      columns2,
      listParam: {
        areaIds: "1",
        name: "",
        natManageUnits: "",
        waterType: "0",
        wasuScales: "0",
        reportYear: "",
        updateMode: "0",
        pageSize: 5,
        pageNumber: 1
      },
      userAreaId: {
        id: 1
      },
      sortClick: false,
      isItem: false
    };
  },
  created() {
    var date = new Date();

    this.listParam.reportYear = date.getFullYear();
    this.year.unshift(date.getFullYear() - 1);
    for (let i = 0; i < 9; i++) {
      this.year = this.year.concat(date.getFullYear() + i);
    }
    this.tabLista =
      this.localGetItem("userLevel") == 3
        ? ["总体分析"]
        : ["总体分析", "区域对比"];
    let area = JSON.parse(localStorage.getItem("areaValue"));
    this.userAreaId.id = area.areaId;
    this.getDefaultAreaList();
    getAreaTreeList(this.userAreaId).then(response => {
      this.options = response.data.list;
    });
  },
  mounted() {
    document.getElementById("qydb").style.display = "none";
    document.getElementById("tableline3").style.display = "none";
  },
  methods: {
    areaChange(value) {
      this.listParam.areaIds = value[value.length - 1];
      this.param.areaId = value[value.length - 1];
    },
    // handleChangeArea(value) {
    //   this.param.areaId = value;
    //   this.param.selectedAreaId = value;
    // },
    getDataA() {
      // this.getIndustries();
      this.getConsumProjects();
      this.getTableData();
      this.getIndustrieDatas();
      this.waterProject1(0);
      this.waterProject2(0);
    },
    getData() {
      this.getIndustries();
      this.getConsumProjects();
      this.getTableData();
      this.getIndustrieDatas();
    },
    // 当前用户能查询的区域
    getDefaultAreaList() {
      let data = getDefaultArea();
      this.param.defaultAreaId = data.id;
      this.param.areaName = data.name;
      this.param.reportYear = 2020;

      if (data.identity == 1) {
        this.queryName = "全省";
      }
      if (data.identity == 2) {
        this.queryName = "全市";
      }
      if (data.identity == 3) {
        this.queryName = "全县";
      }

      if (!this.param.areaId) {
        this.param.areaId = this.param.defaultAreaId;
      }

      getAreas({
        id: this.param.defaultAreaId
      }).then(res => {
        if (res.code == 200) {
          this.xzqh = res.data.list;
          this.getData();
        }
      });
    },
    // 统计数据
    getIndustries() {
      getConsumIndustry({
        areaId: this.param.defaultAreaId,
        reportYear: this.param.reportYear
      }).then(res => {
        if (res.code == 0) {
          res.data.consumProjectPeople.plaCityWasu = 0
          res.data.consumProjectPeople.popCityWasu = 0
          res.data.consumProjectPeople.plaQdwrWasu = 10
          res.data.consumProjectPeople.popQdwrWasu = 23.64
          res.data.consumProjectPeople.wawPow = 98
          res.data.consumProjectPeople.scalePow = 100

          // 供水总人口
          this.industry.cntyTotcounPop = res.data.cntyTotcounPop;
          this.industry.numCity = res.data.numCity;
          this.industry.numCountry = res.data.numCountry;
          this.industry.numTown = res.data.numTown;
          this.industry.numVillage = res.data.numVillage;
          this.industry.rat = this.handleRat(
            res.data.cntyTotcounPop,
            res.data.cntyTotPop
          );

          // 供水工程总数
          this.industry.plaWasu = res.data.consumProjectPeople?.plaWasu;
          this.industry.peoWasu = res.data.consumProjectPeople?.popWasu;
          this.industry.plaCwse = res.data.consumProjectPeople?.plaCwse;
          this.industry.peoCwse = res.data.consumProjectPeople?.peoCwse;
          this.industry.plaCwseRat = this.handleRat(
            this.industry.plaCwse,
            this.industry.plaWasu
          );
          this.industry.plaDwse = res.data.consumProjectPeople?.plaDwse;
          this.industry.peoDwse = res.data.consumProjectPeople?.peoDwse;
          this.industry.plaDwseRat = this.handleRat(
            this.industry.plaDwse,
            this.industry.plaWasu
          );
          this.$chart.ciicle1("ciicle1", [
            {
              value: this.industry.plaCwse,
              name: "集中工程"
            },
            {
              value: this.industry.plaDwse,
              name: "分散工程"
            }
          ]);

          // 规模化供水总数
          this.industry.plaWasuRat = this.handleRat(
            this.industry.peoWasu,
            res.data.cntyTotPop
          );
          this.$chart.bar1("bar1", [
            res.data.consumProjectPeople?.plaCityWasu,
            res.data.consumProjectPeople?.plaQdwrWasu
          ]);
          this.$chart.bar2("bar2", [
            res.data.consumProjectPeople?.popCityWasu,
            res.data.consumProjectPeople?.popQdwrWasu
          ]);
          this.modularThreeLeft =
            res.data.consumProjectPeople?.plaCityWasu +
            res.data.consumProjectPeople?.plaQdwrWasu;
          this.modularThreeRight =
            ((Number(res.data.consumProjectPeople?.popCityWasu) +
              Number(res.data.consumProjectPeople?.popQdwrWasu)) *
              100) /
            100;

          // 集中供水率
          let data_permissions = JSON.parse(
            localStorage.getItem("data_permissions")
          );
          let admin = localStorage.getItem("admin");
          if (admin == "1") {
            this.industry.waterList = [
              Number(res.data.consumProjectPeople.focusWasePow || 0),
              Number(res.data.consumProjectPeople.wawPow || 0),
              Number(res.data.consumProjectPeople.scalePow || 0),
              Number(res.data.consumProjectPeople.endPow || 0),
              Number(res.data.consumProjectPeople.qualityPow || 0)
            ];
          } else {
            // 四率（五率权限）
            let rate = data_permissions[0].children;
            this.tabList = [];
            if (rate) {
              for (let i in rate) {
                if (rate[i].checked) {
                  let column = rate[i].column;
                  this.industry.waterList.push(
                    Number(res.data.consumProjectPeople[column] || 0)
                  );
                  this.tabList.push(rate[i].name);
                }
              }
            } else {
              this.industry.waterList = [];
            }
          }
          this.industry.waterRat = this.industry.waterList[this.waterIndex];
        }
      });
    },
    handleRat(data1, data2) {
      let result = parseFloat(data1) / parseFloat(data2);
      result = (result * 100).toFixed(2);
      return result;
    },
    getConsumProjects() {
      this.industry.consumProjects = [];
      getConsumProject({
        areaId: this.param.areaId,
        reportYear: this.param.reportYear
      }).then(res => {
        if (res.code == 200) {
          let list = res.data.list
          list[1].numPeople = 23.64
          list[1].numProject = 10
          list[1].powNumPeople = 100
          list[1].powNumProject = 100

          list[3].numPeople = 0
          list[3].numProject = 0
          list[3].powNumPeople = 0
          list[3].powNumProject = 0
          this.industry.consumProjects = list;



          let datas = this.handleConsumpRrojects();
          this.$chart.ciicle2("ciicle2", this.waterProjectIndex1, datas);
          this.$chart.line1("line1", this.waterProjectIndex2, this.handlePop());
          this.waterProject2(0);
        }
      });
    },
    getTableData() {
      getAreaIndustries({
        areaId: this.param.areaId,
        reportYear: this.param.reportYear
      }).then(res => {
        if (res.code == 200) {
          for (let i = 0; i < res.data.list.length; i++) {
            let item = res.data.list[i]
            item.plaCityWasu = 0
            item.popCityWasu = 0
            item.plaQdwrWasu = 10
            item.popQdwrWasu = 23.64
            item.popQrWasu = 0
            item.plaQrWasu = 0
            item.popFosWasu = 0
          }
          this.industry.tableData = res.data.list;
          console.log('this.industry.tableData ',this.industry.tableData )
          this.handleTableData();
        }
      });
    },
    //按照某个属性进行排序（示例：按照对象的numPeople属性进行排序）
    sortByNumber(a, b) {
      if (this.sortup) {
        if (this.waterProjectIndex2 == 0) {
          return parseInt(b.numPeople) - parseInt(a.numPeople);
        }
        if (this.waterProjectIndex2 == 1) {
          return parseInt(b.numProject) - parseInt(a.numProject);
        }
        if (this.waterProjectIndex2 == 2) {
          return parseInt(b.powNumPeople) - parseInt(a.powNumPeople);
        }
        if (this.waterProjectIndex2 == 3) {
          return parseInt(b.powNumProject) - parseInt(a.powNumProject);
        }
      } else {
        if (this.waterProjectIndex2 == 0) {
          return parseInt(a.numPeople) - parseInt(b.numPeople);
        }
        if (this.waterProjectIndex2 == 1) {
          return parseInt(a.numProject) - parseInt(b.numProject);
        }
        if (this.waterProjectIndex2 == 2) {
          return parseInt(a.powNumPeople) - parseInt(b.powNumPeople);
        }
        if (this.waterProjectIndex2 == 3) {
          return parseInt(a.powNumProject) - parseInt(b.powNumProject);
        }
      }
    },
    // 全省农村供水工程分类
    handleConsumpRrojects() {
      let title = [];
      let data = [];
      for (let i in this.industry.consumProjects) {
        let result = this.industry.consumProjects[i];
        title.push(result.nameProjectType);
        if (this.waterProjectIndex1 == 0) {
          data.push({
            value: result.powNumPeople * 100,
            name: result.nameProjectType
          });
        } else {
          data.push({
            value: result.powNumProject * 100,
            name: result.nameProjectType
          });
        }
      }
      return {
        title: title,
        data: data
      };
    },
    // 农村供水人口分布数据处理
    handlePop() {
      let data = [];
      let title = [];
      for (let i in this.industry.consumProjects) {
        let result = this.industry.consumProjects[i];
        title.push(result.nameProjectType);
        if (this.waterProjectIndex2 == 0) {
          data.push(result.numPeople);
        }
        if (this.waterProjectIndex2 == 1) {
          data.push(result.numProject);
        }
        if (this.waterProjectIndex2 == 2) {
          data.push(result.powNumPeople);
        }
        if (this.waterProjectIndex2 == 3) {
          data.push(result.powNumProject);
        }
      }
      if (this.sortClick) {
        this.industry.consumProjects.sort(this.sortByNumber);
      }
      return {
        title: title,
        data: data
      };
    },
    //跳转
    router(x) {
      this.$router.push({
        path: x
      });
    },
    //切换集中供水率
    checkWater(index) {
      this.waterIndex = index;
      this.industry.waterRat = this.industry.waterList[index];
      this.waterName = this.tabList[index];
    },
    // 切换工程运行管理单位
    checkProject(item) {
      if (this.projectIndex.indexOf(item) == -1) {
        this.projectIndex.push(item);
      } else {
        this.projectIndex.splice(
          this.projectIndex.findIndex(i => i === item),
          1
        );
      }
    },
    // 切换总体分析/区域对比
    checkContrast(index) {
      this.contrastIndex = index;
      if (index == 0) {
        document.getElementById("ztfx").style.display = "block";
        document.getElementById("qydb").style.display = "none";
        this.isYX = false;
        this.$chart.ciicle2(
          "ciicle2",
          this.waterProjectIndex1,
          this.handleConsumpRrojects()
        );
        this.$chart.line1("line1", this.waterProjectIndex2, this.handlePop());
      } else {
        getAreaIndustries({
          areaId: this.param.areaId,
          reportYear: this.param.reportYear
        }).then(res => {
          if (res.code == 200) {
            this.industry.tableData = res.data.list;
            let data = [];
            let data_ot = [];
            let title = [];
            for (let i in res.data.list) {
              let result = res.data.list[i];
              title.push(result.adName);
              data.push(result.peoCwse);
              data_ot.push(result.peoDwse);
            }
            this.$chart.line2("line2", {
              title: title,
              data: data,
              data2: data_ot
            });

            this.$chart.line3(
              "line3",
              this.tabListeIndex2,
              this.handleAreaIndustries()
            );
          }
        });
        document.getElementById("qydb").style.display = "block";
        document.getElementById("ztfx").style.display = "none";
        this.isYX = false;
      }
    },
    getIndustrieDatas() {
      this.data = [];
      getIndustrieList({
        areaId: this.param.areaId,
        reportYear: this.param.reportYear,
        queryBy: 0
      }).then(res => {
        if (res.code == 200) {
          for (let i in res.data.list) {
            let result = res.data.list[i];
            result.consumProjectPeople.plaCityWasu = 0
            result.consumProjectPeople.popCityWasu = 0
            result.consumProjectPeople.plaQdwrWasu = 10
            result.consumProjectPeople.popQdwrWasu = 23.64

            result.consumProjectPeople.popQrWasu = 0
            result.consumProjectPeople.plaQrWasu = 0
            // result.consumProjectPeople.popQdwrWasu = 23.64
            // result.consumProjectPeople.popQdwrWasu = 23.64

            this.data.push({
              num: Number(i) + 1,
              key: i,
              name: result.adName,
              allpopulation: result.cntyTotcounPop,
              allnum: result.consumProjectPeople.plaWasu,
              num1: result.consumProjectPeople.plaCwse,
              population1: result.consumProjectPeople.peoCwse,
              num2: result.consumProjectPeople.plaCityWasu,
              population2: result.consumProjectPeople.popCityWasu,
              num3: result.consumProjectPeople.plaQdwrWasu,
              population3: result.consumProjectPeople.popQdwrWasu,
              num4: result.consumProjectPeople.plaQdWasu,
              population4: result.consumProjectPeople.popQdWasu,
              // num5: result.consumProjectPeople.plaFosWasu,
              // population5: result.consumProjectPeople.popFosWasu,
              num5: result.consumProjectPeople.plaQrWasu,
              population5: result.consumProjectPeople.popQrWasu,
              num6: result.consumProjectPeople.plaDwse,
              population6: result.consumProjectPeople.peoDwse
            });
          }
        }
      });
    },
    // 处理表格数据
    handleTableData() {
      this.data1 = [];
      this.data2 = [];
      for (let i in this.industry.tableData) {
        let result = this.industry.tableData[i];



        this.data1.push({
          num: Number(i) + 1,
          key: i,
          name: result.adName,
          allnum: result.plaCwse + result.plaDwse,
          num1: result.peoCwse,
          population1: result.plaCwse,
          percent1: this.handleRat(result.peoCwse, result.popWasu),
          percent2: this.handleRat(result.plaCwse, result.plaWasu),
          num2: result.peoDwse,
          population2: result.plaDwse,
          percent3: this.handleRat(result.peoDwse, result.popWasu),
          percent4: this.handleRat(result.plaDwse, result.plaWasu)
        });

        this.data2.push({
          num: Number(i) + 1,
          key: i,
          name: result.adName,
          num1: result.peoCwse,
          population1: result.plaCwse,
          percent1: this.handleRat(result.peoCwse, result.popWasu),
          percent2: this.handleRat(result.plaCwse, result.plaWasu),
          num2: result.popCityWasu,
          population2: result.plaCityWasu,
          percent3: this.handleRat(result.popCityWasu, result.popWasu),
          percent4: this.handleRat(result.plaCityWasu, result.plaWasu),
          num3: result.popQdwrWasu,
          population3: result.plaQdwrWasu,
          percent5: this.handleRat(result.popQdwrWasu, result.popWasu),
          percent6: this.handleRat(result.plaQdwrWasu, result.plaWasu),
          num4: result.popQdWasu,
          population4: result.plaQdWasu,
          percent7: this.handleRat(result.popQdWasu, result.popWasu),
          percent8: this.handleRat(result.plaQdWasu, result.plaWasu),
          // num5: result.popFosWasu,
          num5: result.popQrWasu,
          // population5: result.plaFosWasu,
          population5: result.plaQrWasu,
          // percent9: this.handleRat(result.popFosWasu, result.popWasu),
          percent9: this.handleRat(result.popQrWasu, result.popWasu),
          // percent10: this.handleRat(result.plaFosWasu, result.plaWasu),
          percent10: this.handleRat(result.plaQrWasu, result.plaWasu),

          num6: result.peoDwse,
          population6: result.plaDwse,
          percent11: this.handleRat(result.peoDwse, result.popWasu),
          percent12: this.handleRat(result.plaDwse, result.plaWasu)
        });
      }
    },
    //按照某个属性进行排序（示例：按照对象的numPeople属性进行排序）
    sortByNumberA(a, b) {
      if (this.sortup1) {
        return b.value * 100 - a.value * 100;
      } else {
        return a.value * 100 - b.value * 100;
      }
    },
    haveOnchange(x) {
      let all = {
        allNum: 0,
        allNum1: 0,
        allNum2: 0,
        allNum3: 0
      };
      if (this.value == 1) {
        for (let i = 0; i < this.es.length; i++) {
          if (this.es[i].target.checked && this.es[i].target.value == 3) {
            all.allNum = all.allNum + Number(this.data2[x].num2);
            all.allNum1 = all.allNum1 + Number(this.data2[x].population2);
            all.allNum2 = all.allNum2 + Number(this.data2[x].percent3);
            all.allNum3 = all.allNum3 + Number(this.data2[x].percent4);
          }
          if (this.es[i].target.checked && this.es[i].target.value == 4) {
            all.allNum = all.allNum + Number(this.data2[x].num3);
            all.allNum1 = all.allNum1 + Number(this.data2[x].population3);
            all.allNum2 = all.allNum2 + Number(this.data2[x].percent5);
            all.allNum3 = all.allNum3 + Number(this.data2[x].percent6);
          }
          if (this.es[i].target.checked && this.es[i].target.value == 5) {
            all.allNum = all.allNum + Number(this.data2[x].num4);
            all.allNum1 = all.allNum1 + Number(this.data2[x].population3);
            all.allNum2 = all.allNum2 + Number(this.data2[x].percent7);
            all.allNum3 = all.allNum3 + Number(this.data2[x].percent8);
          }
          if (this.es[i].target.checked && this.es[i].target.value == 6) {
            all.allNum = all.allNum + Number(this.data2[x].num5);
            all.allNum1 = all.allNum1 + Number(this.data2[x].population3);
            all.allNum2 = all.allNum2 + Number(this.data2[x].percent9);
            all.allNum3 = all.allNum3 + Number(this.data2[x].percent10);
          }
        }
      } else {
        all.allNum = Number(this.data2[x].num6);
        all.allNum1 = Number(this.data2[x].population6);
        all.allNum2 = Number(this.data2[x].percent11);
        all.allNum3 = Number(this.data2[x].percent12);
      }

      return all;
    },
    toDecimal(x) {
      var f = parseFloat(x);
      if (isNaN(f)) {
        return;
      }
      f = Math.round(x * 100) / 100;
      return f;
    },
    handleAreaIndustries() {
      let data = [];
      let title = [];
      let arr = this.data2;

      for (let i in arr) {
        let result = arr[i];
        if (this.tabListeIndex2 == 0) {
          data.push({
            title: result.name,
            value: this.toDecimal(this.haveOnchange(i).allNum)
          });
        }
        if (this.tabListeIndex2 == 1) {
          data.push({
            title: result.name,
            value: this.toDecimal(this.haveOnchange(i).allNum1)
          });
        }
        if (this.tabListeIndex2 == 2) {
          data.push({
            title: result.name,
            value: this.toDecimal(this.haveOnchange(i).allNum2)
          });
        }
        if (this.tabListeIndex2 == 3) {
          data.push({
            title: result.name,
            value: this.toDecimal(this.haveOnchange(i).allNum3)
          });
        }
      }
      data.sort(this.sortByNumberA);
      title = [];
      for (let y in data) {
        title.push(data[y].title);
      }
      return {
        title: title,
        data: data
      };
    },
    // 切换全省农村供水工程分类/人口占比/工程占比
    waterProject1(index) {
      this.waterProjectIndex1 = index;
      this.$chart.ciicle2(
        "ciicle2",
        this.waterProjectIndex1,
        this.handleConsumpRrojects()
      );
    },
    // 切换全省农村供水工程分类/人口占比/工程占比/占比
    waterProject2(index) {
      this.waterProjectIndex2 = index;
      this.$chart.line1("line1", this.waterProjectIndex2, this.handlePop());
    },
    // 集中供水工程覆盖人口/人口占比/工程占比/占比
    waterProject3(index) {
      this.tabListeIndex2 = index;
      this.sortClick = false;
      this.$chart.line3(
        "line3",
        this.tabListeIndex2,
        this.handleAreaIndustries()
      );
    },
    // 切换农村供水人口分布
    tabListeCheck(index) {
      this.tabListeIndex = index;
      if (index == 0) {
        document.getElementById("tuline2").style.display = "block";
        document.getElementById("tableline2").style.display = "none";
      } else {
        document.getElementById("tableline2").style.display = "block";
        document.getElementById("tuline2").style.display = "none";
      }
    },
    // 切换农村供水人口分布
    tabListeCheck1(index) {
      this.tabListeIndex1 = index;
      if (index == 0) {
        document.getElementById("tuline3").style.display = "block";
        document.getElementById("tableline3").style.display = "none";
      } else {
        document.getElementById("tableline3").style.display = "block";
        document.getElementById("tuline3").style.display = "none";
      }
    },
    // 全省农村供水工程分类/人口占比/工程占比/占比排序
    sort() {
      this.sortup = !this.sortup;
      this.sortClick = true;
      this.$chart.line1("line1", this.waterProjectIndex2, this.handlePop());
    },
    // 农村供水人口分布/人口占比/工程占比/占比排序
    sort1() {
      this.sortup1 = !this.sortup1;
      this.$chart.line3(
        "line3",
        this.tabListeIndex2,
        this.handleAreaIndustries()
      );
    },
    //查询
    handleSearch(e) {
      e.preventDefault();
      this.waterIndex = 0;
      // this.getData();
      this.getDataA();
    },
    //清除
    handleReset() {
      this.waterIndex = 0;
      this.param.selectedAreaId = undefined;
      this.param.areaId = this.param.defaultAreaId;
      this.getData();
    },
    toDetail() {
      this.$router.push({
        path: "/index/industry/index/detail",
        query: {
          id: this.param.areaId,
          year: this.param.reportYear
        }
      });
    },
    //多选运行管理单位
    handleChange(value) {
      this.param.areaId = value;
    },
    //多选年度
    handleChange2(value) {
      this.param.reportYear = this.year[value];
    },
    //工程类型 单选
    onChange(e) {
      if (e.target.value == "1") {
        this.one = 1;
        this.columns2[2] = {
          title: "集中供水工程",
          children: [
            {
              title: "供水人数(万人)",
              dataIndex: "num1",
              key: "num1",
              width: 90,
              align: "center"
            },
            {
              title: "工程处数(处)",
              dataIndex: "population1",
              key: "population1",
              width: 90,
              align: "center"
            },
            {
              title: "人口占比(%)",
              dataIndex: "percent1",
              key: "percent1",
              width: 90,
              align: "center"
            },
            {
              title: "工程占比(%)",
              dataIndex: "percent2",
              key: "percent2",
              width: 90,
              align: "center"
            }
          ]
        };
        this.$set(this.columns2, 1, this.columns2[1]);
      }

      if (e.target.value == "2") {
        this.one = 2;

        this.columns2[2] = {
          title: "分散供水工程",
          children: [
            {
              title: "供水人数(万人)",
              dataIndex: "num6",
              key: "num6",
              width: 90,
              align: "center"
            },
            {
              title: "工程处数(处)",
              dataIndex: "population6",
              key: "population6",
              width: 90,
              align: "center"
            },
            {
              title: "人口占比(%)",
              dataIndex: "percent11",
              key: "percent11",
              width: 90,
              align: "center"
            },
            {
              title: "工程占比(%)",
              dataIndex: "percent12",
              key: "percent12",
              width: 90,
              align: "center"
            }
          ]
        };
        this.columns2 = this.columns2.slice(0, 3);
        this.$set(this.columns2, 1, this.columns2[1]);
        console.log(this.columns2);
        for (let i = 0; i < this.es.length; i++) {
          this.es[i].target.checked = false;
        }
        this.es = this.es;
      }
      this.$chart.line3(
        "line3",
        this.tabListeIndex2,
        this.handleAreaIndustries()
      );
    },
    unique(arr) {
      console.log(arr);
      let newArr = [];
      let obj = {};
      for (var i = 0; i < arr.length; i++) {
        if (!obj[arr[i].title]) {
          newArr.push(arr[i]);
          obj[arr[i].title] = true;
        }
      }
      return newArr;
    },
    // 多选
    onChange1(e) {
      for (let i = 0; i < this.es.length; i++) {
        if (this.es[i].target.value == 3 && this.es[i].target.checked == true) {
          this.columns2[3] = {
            title: "城市管网延伸工程",
            children: [
              {
                title: "供水人数(万人)",
                dataIndex: "num2",
                key: "num2",
                width: 90,
                align: "center"
              },
              {
                title: "工程处数(处)",
                dataIndex: "population2",
                key: "population2",
                width: 90,
                align: "center"
              },
              {
                title: "人口占比(%)",
                dataIndex: "percent3",
                key: "percent3",
                width: 90,
                align: "center"
              },
              {
                title: "工程占比(%)",
                dataIndex: "percent4",
                key: "percent4",
                width: 90,
                align: "center"
              }
            ]
          };
          this.$set(this.columns2, 2, this.columns2[2]);
        }
        if (this.es[i].target.value == 4 && this.es[i].target.checked == true) {
          this.columns2[4] = {
            title: "万人供水工程",
            children: [
              {
                title: "供水人数(万人)",
                dataIndex: "num3",
                key: "num3",
                width: 90,
                align: "center"
              },
              {
                title: "工程处数(处)",
                dataIndex: "population3",
                key: "population3",
                width: 90,
                align: "center"
              },
              {
                title: "人口占比(%)",
                dataIndex: "percent5",
                key: "percent5",
                width: 90,
                align: "center"
              },
              {
                title: "工程占比(%)",
                dataIndex: "percent6",
                key: "percent6",
                width: 90,
                align: "center"
              }
            ]
          };
          this.$set(this.columns2, 3, this.columns2[3]);
        }
        if (this.es[i].target.value == 5 && this.es[i].target.checked == true) {
          this.columns2[5] = {
            title: "千人供水工程",
            children: [
              {
                title: "供水人数(万人)",
                dataIndex: "num4",
                key: "num4",
                width: 90,
                align: "center"
              },
              {
                title: "工程处数(处)",
                dataIndex: "population4",
                key: "population4",
                width: 90,
                align: "center"
              },
              {
                title: "人口占比(%)",
                dataIndex: "percent7",
                key: "percent7",
                width: 90,
                align: "center"
              },
              {
                title: "工程占比(%)",
                dataIndex: "percent8",
                key: "percent8",
                width: 90,
                align: "center"
              }
            ]
          };
          this.$set(this.columns2, 4, this.columns2[4]);
        }
        if (this.es[i].target.value == 6 && this.es[i].target.checked == true) {
          this.columns2[6] = {
            title: "千人以下集中供水工程",
            children: [
              {
                title: "供水人数(万人)",
                dataIndex: "num5",
                key: "num5",
                width: 90,
                align: "center"
              },
              {
                title: "工程处数(处)",
                dataIndex: "population5",
                key: "population5",
                width: 90,
                align: "center"
              },
              {
                title: "人口占比(%)",
                dataIndex: "percent9",
                key: "percent9",
                width: 90,
                align: "center"
              },
              {
                title: "工程占比(%)",
                dataIndex: "percent10",
                key: "percent10",
                width: 90,
                align: "center"
              }
            ]
          };
          this.$set(this.columns2, 5, this.columns2[5]);
        }
        if (
          this.es[i].target.value == 3 &&
          this.es[i].target.checked == false
        ) {
          this.columns2.splice(3, 1);
          // this.columns2[2] = {};
          // this.$set(this.columns2, 2, this.columns2[2]);
        }
        if (
          this.es[i].target.value == 4 &&
          this.es[i].target.checked == false
        ) {
          this.columns2.splice(4, 1);
          // this.columns2[3] = {};
          // this.$set(this.columns2, 3, this.columns2[3]);
        }
        if (
          this.es[i].target.value == 5 &&
          this.es[i].target.checked == false
        ) {
          this.columns2.splice(5, 1);
          // this.columns2[4] = {};
          // this.$set(this.columns2, 4, this.columns2[4]);
        }
        if (
          this.es[i].target.value == 6 &&
          this.es[i].target.checked == false
        ) {
          this.columns2.splice(6, 1);
          // this.$set(this.columns2, 5, this.columns2[5]);
        }
      }
      console.log(this.columns2);
      for (let y = 0; y < this.columns2.length; y++) {
        console.log(11111111);
        console.log(this.columns2[y]);
        if (this.columns2[y] == undefined) {
          console.log(22222222);

          this.columns2.splice(y, 1);
          console.log(this.columns2[y]);
        }
      }
      this.columns2 = this.unique(this.columns2);

      this.$chart.line3(
        "line3",
        this.tabListeIndex2,
        this.handleAreaIndustries()
      );
      console.log("最终值", this.columns2);
    },
    // 工程类型 点击
    downClick() {
      document.getElementById("checkboxis").style.display = "block";
      document.getElementById("down").style.display = "none";
      document.getElementById("up").style.display = "block";
    },
    // 工程类型 点击
    upClick() {
      document.getElementById("checkboxis").style.display = "none";
      document.getElementById("up").style.display = "none";
      document.getElementById("down").style.display = "block";
    }
  }
};
</script>

<style scoped lang="less">
@media screen and (max-width: 1300px) {
  #bar1 {
    margin: -40px 0px -25px;
  }
  #bar2 {
    margin: -40px 0px -25px;
  }
}
#tableline2 /deep/ .ant-table-body {
  overflow-x: hidden !important;
}
.industry-progress /deep/ .ant-progress-inner {
  background-color: #e0e0e0 !important;
}
.industry-topfirst-con-top {
  font-size: 25px;
}
.industry-top {
  display: flex;
  justify-content: space-between;
  .industry-topone {
    width: 24%;
    // height: max-content;
    min-height: 280px;
    background: #ffffff;
    box-sizing: border-box;
    padding: 20px;
    .industry-topfirst-title {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      height: 51px;
      .industry-topfirst-title-l {
        font-size: 16px;
        font-weight: bold;
        width: max-content;
      }
      .industry-topfirst-title-r {
        font-size: 18px;
        font-weight: bold;
      }
    }
    .industry-topfirst-chart1 {
      margin-top: 20px;
      position: relative;
      .percent1 {
        position: absolute;
        text-align: center;
        left: 25px;
        top: 30%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
      .percent2 {
        position: absolute;
        text-align: center;
        right: -30px;
        top: 30%;
        transform: translate(-50%, -50%);
      }
    }
    .industry-topfirst-con {
      width: 100%;
      height: 60%;
      text-align: center;
      margin-top: 50px;
      // display: flex;
      // flex-flow: column;
      // flex-wrap: wrap;
      // align-items: center;
      // justify-content: center;
      // margin: 0px;
      font-size: 21px;

      .industry-topfirst-con-bottom {
        // display: flex;
        align-items: flex-end;
        justify-content: center;
        border-bottom: 1px solid #e1e1e1;
        padding: 18px 0px 20px;

        .industry-topfirst-con-bottom-left {
          font-size: 18px;
          line-height: 18px;
        }
        .industry-topfirst-con-bottom-right {
          font-size: 25px;
          line-height: 25px;
        }
      }
    }
    .industry-topfirst-text {
      text-align: center;
      margin-top: 27px;
      div {
        // margin-top: 10px;
      }
    }
    .industry-topfirst-chart2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 65%;
      .chart2-left {
        width: 49%;
      }
      .chart2-right {
        width: 49%;
      }
    }
    .industry-topfirst-chart2-text {
      width: 100%;
      text-align: center;
    }
    .industry-progress {
      text-align: center;
      margin-top: 40px;
      div {
        margin-top: 20px;
      }
    }
    .industry-progress-tab {
      width: 100%;
      display: flex;
      font-size: 12px;
      justify-content: space-between;
      margin-top: 50px;
      .industry-progress-tabone {
        position: relative;
        cursor: pointer;
        text-align: center;
        div {
          width: 50px;
          height: 1px;
          background: #1890ff;
          position: absolute;
          transform: translate(-50%, -50%);
          left: 50%;
          bottom: -4px;
          display: none;
        }
      }
      .progress-tabone-select {
        color: #1890ff;
        div {
          display: block;
        }
      }
    }
  }
}
.industry-second {
  width: 100%;
  // height: 204px;
  background: #ffffff;
  margin-top: 20px;
  .industry-second-up {
    padding: 30px 20px 15px 20px;
    border-bottom: 1px solid #e1e1e1;
  }
  .industry-second-ud {
    display: flex;
    padding: 20px 0 20px 0;
    .industry-second-udone {
      position: relative;
      cursor: pointer;
      width: 105px;
      text-align: center;
      div {
        width: 50px;
        height: 1px;
        background: #1890ff;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: -4px;
        display: none;
      }
    }
    .progress-tabone-select {
      color: #1890ff;
      div {
        display: block;
      }
    }
  }
  .industry-second-up-select {
    display: flex;
    align-items: flex-start;
    .industry-second-up-select-l {
      width: 200px;
      height: 40px;
      line-height: 40px;
    }
    .industry-second-up-select-r {
      display: flex;
      flex-wrap: wrap;
      div {
        height: 40px;
        margin-right: 10px;
        padding: 0 15px;
        text-align: center;
        line-height: 40px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .select-rd {
        color: #ffffff;
        background: #1890ff;
      }
    }
  }
}
.industry-third {
  // margin-top: 20px;
  border-top: 1px solid #e1e1e1;
  display: flex;
  justify-content: space-between;
  .industry-thirdone {
    overflow: hidden;
    width: 49.2%;
    min-height: 400px;
    background: #ffffff;
    padding: 24px 20px 10px;
    .industry-thirdone-title {
      display: flex;
      // align-items: center;
      align-items: flex-start;
      justify-content: space-between;
      .industry-thirdone-title-l {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        .line {
          width: 4px;
          height: 16px;
          background: #1890ff;
          border-radius: 2px;
        }
        .title {
          margin-left: 8px;
        }
      }
    }
    .linesort {
      right: 40px;
      top: 20px;
      position: absolute;
      cursor: pointer;
      z-index: 9;
      img {
        width: 21px;
        height: 19px;
      }
    }
  }
}
.industry-thirdone-title-r {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  .industry-thirdone-title-rdone {
    width: 80px;
    height: 24px;
    line-height: 24px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    font-size: 12px;
    color: #999999;
    text-align: center;
  }
  .industry-thirdone-title-rselect {
    color: #ffffff;
    background: #1890ff;
    border: 1px solid #1890ff;
  }
}
.industry-four {
  min-height: 400px;
  background: #ffffff;
  margin-top: 20px;
  padding: 20px;
  .industry-four-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .industry-four-title-l {
      font-size: 14px;
      color: #ff3e3e;
    }
    .industry-four-title-r {
      font-size: 12px;
      color: #999999;
      cursor: pointer;
    }
  }
  .industry-four-table {
    margin-top: 20px;
  }
}
.industry-five {
  width: 100%;
  height: 660px;
  min-height: 660px;
  background: #ffffff;
  // margin-top: 20px;
  border-top: 1px solid #e1e1e1;
  padding: 20px;
  .industry-thirdone-title {
    display: flex;
    // align-items: center;
    align-items: flex-start;
    justify-content: space-between;
    .industry-thirdone-title-l {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      .line {
        width: 4px;
        height: 16px;
        background: #1890ff;
        border-radius: 2px;
      }
      .title {
        margin-left: 8px;
      }
    }
    .industry-thirdone-title-r {
      display: flex;
      margin-top: 20px;
      cursor: pointer;
      .industry-thirdone-title-rdone {
        width: 80px;
        height: 24px;
        line-height: 24px;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        font-size: 12px;
        color: #999999;
        text-align: center;
      }
      .industry-thirdone-title-rselect {
        color: #ffffff;
        background: #1890ff;
        border: 1px solid #1890ff;
      }
    }
  }
}
.industry-six {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .industry-six-l {
    width: 25%;
    height: 600px;
    background: #ffffff;
    padding: 20px;
    .industry-thirdone-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .industry-thirdone-title-l {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        .line {
          width: 4px;
          height: 16px;
          background: #1890ff;
          border-radius: 2px;
        }
        .title {
          margin-left: 8px;
        }
      }
    }
    .industry-six-l-con {
      display: flex;
      justify-content: center;
      .radio1 {
        margin-top: 50px;
        display: flex;
        align-items: center;
        .down {
          cursor: pointer;
          display: none;
        }
        .up {
          cursor: pointer;
        }
      }
      .radio2 {
        margin-top: 30px;
      }
      .checkbox {
        margin-left: 30px;
        display: block;
        .checkbox-s {
          margin-top: 20px;
        }
      }
    }
  }
  .industry-six-r {
    width: 73.5%;
    height: 600px;
    background: #ffffff;
    padding: 20px;
    .industry-thirdone-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .industry-thirdone-title-l {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        .line {
          width: 4px;
          height: 16px;
          background: #1890ff;
          border-radius: 2px;
        }
        .title {
          margin-left: 8px;
        }
      }
      .industry-thirdone-title-r {
        display: flex;
        margin-top: 20px;

        cursor: pointer;
        .industry-thirdone-title-rdone {
          width: 80px;
          height: 24px;
          line-height: 24px;
          background: #ffffff;
          border: 1px solid #e1e1e1;
          font-size: 12px;
          color: #999999;
          text-align: center;
        }
        .industry-thirdone-title-rselect {
          color: #ffffff;
          background: #1890ff;
          border: 1px solid #1890ff;
        }
      }
    }
    .linesort {
      right: 40px;
      top: 20px;
      position: absolute;
      z-index: 9;
      cursor: pointer;
      img {
        width: 21px;
        height: 19px;
      }
    }
  }
}
</style>
